import { setupCarousels } from "../scripts/dragscroll-carousels";
import { highlightPunctuation } from "../scripts/highlight-punctuation";
import { setupPictureSelectSections } from "../scripts/picture-select-sections";



import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

import ObjectPosition from "@vovayatsyuk/photoswipe-object-position";

document.addEventListener('DOMContentLoaded', function () {
  highlightPunctuation();
  setupCarousels();
  setupPictureSelectSections();


  if (Shopify.designMode) {
    console.log('Design mode is on', Shopify);
  }

  const lightbox = new PhotoSwipeLightbox({
    gallery: '.lightbox-wrapper',
    children: 'a.lightbox-image',
    preloaderDelay: 0,
    pswpModule: () => import('photoswipe')
  });
  new ObjectPosition(lightbox);
  lightbox.init();
  document.addEventListener('shopify:section:load', () => {
    highlightPunctuation();
    setupCarousels();
    setupPictureSelectSections();
    lightbox.destroy();
    lightbox.init();
  })

}, false);

