export function setupPictureSelectSections() {
  // console.log('setupPictureSelectSections')
  const picSelectSections = [...document.getElementsByClassName('section-picture-select')]
  picSelectSections.forEach((ele) => {
    if(ele.hasAttribute('data-initialized')) {
      return;
    }
    ele.dataset.initialized = true;
    // console.log('Found not initialized picture select section')

    const pictures = [...ele.getElementsByClassName('section-picture-image')]
    let activeIndex = 0;
    const buttons = [...ele.getElementsByClassName('section-picture-button')]
    buttons.forEach((button, index) => {
      button.addEventListener('click', () => {
        // console.log('button clicked', index, activeIndex)
        pictures[activeIndex].classList.add('hidden')
        pictures[index].classList.remove('hidden')
        buttons[activeIndex].classList.remove('button_active')
        buttons[index].classList.add('button_active')
        activeIndex = index;
      })
    })
})
}
